// import Storage from './Storage';
import log from './logger';
import getValueFromObjectList from './getValueFromObjectList';

const getUserId = () => {
  // start auth0 integration // place this to DMP-W
  let auth0Id;
  let ciamId;
  try {
    const auth0SdkId = window.__NEXT_DATA__.runtimeConfig.AUTH0_CLIENT_ID;
    if (auth0SdkId) {
      auth0Id = window.CIAM.getUserInfo(auth0SdkId)
        .then(result => result.sub)
        .catch(error => {
          log('Auth0-SDK Error: ', error);
        });
    }
  } catch (error) {
    log('getUserId: ', error);
  } finally {
    const eid = getValueFromObjectList(USER);
    ciamId = Promise.resolve(eid && eid.value ? eid.value : undefined); // CIAM ID
  }
  return auth0Id || ciamId;
};
export default getUserId;
