import log from './logger';
import Storage from './Storage';

const getValueFromObjectList = (arr = []) => {
  const getValue = obj => {
    let objSplitted;
    let value = null;
    let property = obj.name;
    switch (obj.type) {
      case 'object':
        objSplitted = obj.name.split('.');
        property = objSplitted[objSplitted.length - 1];
        value = window[objSplitted[0]];
        objSplitted.forEach((val, index) => {
          if (index > 0) {
            try {
              value = value[objSplitted[index]];
            } catch (err) {
              log(err);
            }
          }
        });
        break;
      case 'localStorage':
        value = Storage.get(obj.name, 'localStorage');
        objSplitted = obj.name.split('.');
        property = objSplitted[objSplitted.length - 1];
        break;
      case 'cookie':
        if (document.cookie && obj.name) {
          const name = `${obj.name}=`;
          const decodedCookie = decodeURIComponent(document.cookie);
          const ca = decodedCookie.split(';');
          for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
              value = c.substring(name.length, c.length);
            }
          }
        }
        break;
      case 'url':
        value = document.location.search;
        if (value) {
          value = value.match(`[\\?&]${obj.name}=([^\\?&]+)`);
          value ? (value = value[1]) : (value = null);
          property = obj.name;
        }
        break;
      case 'method': {
        objSplitted = obj.name.split('.');
        property = objSplitted[objSplitted.length - 1];
        const method = objSplitted
          .slice(-1)[0]
          .match(/(.*)\(\\?'?([^\\']*)\\?'?\)/);
        value = window[objSplitted[0]];
        objSplitted.pop(); // last element can be removed as it is not an object
        objSplitted.forEach((val, index) => {
          if (index > 0) {
            value = value[objSplitted[index]];
          }
        });
        value = value[method[1]](method[2]);
        break;
      }
      case 'datalayer':
        value = window.dataLayer.find(data => data.event === 'pageview')[
          obj.name
        ];
        break;
      case 'dataAttribute':
        try {
          const script = document.querySelector(`script[${obj.name}]`);
          value = script && script.getAttribute(obj.name);
        } catch (err) {
          log(err);
        }
        break;
      case 'static':
        objSplitted = obj.name.split(':');
        if (objSplitted.length > 1) {
          property = objSplitted[0];
          value = objSplitted[1];
        }
        break;
      default:
      // do nothing
    }

    // we are allready logging in the Storage class
    if (obj.type !== 'localStorage') {
      log(`get ${obj.name}=${value} from ${obj.type}`);
    }

    return [value, property];
  };

  return arr.find(el => {
    const [value, property] = getValue(el);
    el.value = value;
    el.property = property;

    return value !== null;
  });
};

export default getValueFromObjectList;
